import { filter } from "lodash";
import { Icon } from "@iconify/react";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { get_root_value } from "src/utils/domUtils";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Pagination,
  Tooltip,
} from "@mui/material";
// components
// import Page from "../components/Page";
// import Label from "./Label";
// import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "src/components/NotFound/SearchNotFound";
import DeletedModal from "src/components/ModalPopover/DeleteModal";
import Label from "src/components/Label";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "src/components/_dashboard/user";
// import UserListHead from "./UserListHead";
// import GoalStatementMenu from "../components/_dashboard/user/GoalStatementMenu";
// import { QuestionListing } from "src/DAL/GoalStatement/GoalStatement";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { convertToTitleCase, htmlDecode } from "src/utils/convertHtml";
import CustomPopoverSection from "src/components/MenuOption/CustomPopoverSection";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  deleteProgrammeSectionApi,
  programmeAutoGroupList,
  programmeSectionList,
  deleteProgrammeAutoGroupApi,
} from "src/DAL/Programme/Programme";
import CustomConfirmation from "src/components/ModalPopover/Confirmation";
import { memberListing } from "src/DAL/member/member";
import { s3baseUrl } from "src/config/config";
import {
  consultantListing,
  DeleteConsultantApi,
} from "src/DAL/consultant/consultant";
import { emailTemplateApi } from "src/DAL/SiteSetting/siteSetting";
import { deleteFormApi, formsListApi } from "src/DAL/Forms/Forms";
import moment from "moment";
import { bookingList } from "src/DAL/Transaction/transactionApi";
import { convertCurrencyToSign } from "src/utils/constant";

//
// import USERLIST from "../_mocks_/user";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "action", label: "Action", alignRight: false },
  { id: "number", label: "#", alignRight: false },
  { id: "email", label: "Member", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "transactionDate", label: "Transaction Date", alignRight: false },
  { id: "created", label: "Created By", alignRight: false },
  { id: "type", label: "Transaction Type", alignRight: false },
  { id: "payment_method", label: "Payment Method", alignRight: false },
  { id: "refUser", label: "Transaction Mode", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase().trim()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function Bookings() {
  const navigate = useNavigate();
  const params = useLocation();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("number");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [member, setData] = useState([]);

  const [UserId, setUserId] = useState(false);

  const handleOpen = (e) => {
    setUserId(e);
    setOpen(true);
  };

  const handleRequestSort = (event, property) => {
    // const isAsc = orderBy === property && order === "asc";
    // setOrder(isAsc ? "desc" : "asc");
    // setOrderBy(property);
  };

  const getConsultantListing = async () => {
    setIsLoading(true);

    try {
      setIsLoading(true);
      const result = await bookingList();
      if (result.code === 200) {
        console.log(result, "resultresultresult ");
        setData(result.booking);
        setIsLoading(false);
        const data = result.booking.map((transaction) => {
          return {
            ...transaction,
            id: transaction._id,
            amount: transaction.amount,
            email: transaction?.parent_info?.email,
            transaction_type: transaction.transaction_type,
            transaction_date: transaction.transaction_date,
            currency: transaction.currency,
            refUser: transaction?.affliliate,
            transaction_mode: transaction?.transaction_mode,
            status: transaction?.transaction_status,
            name: transaction?.parent_info?.user_name,
          };
        });
        setUserList(data);
        setIsLoading(false);
        setTotalPages(result.dynamic_form.length / rowsPerPage);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsLoading(false);
      }
    } catch (error) {}
  };
  const handleEdit = (value) => {
    navigate(`/form-templates/edit-template/${value.id}`, {
      state: value,
    });
  };
  const handlePreview = (value) => {
    navigate(`/form-templates/preview-template/${value.id}`, {
      state: value,
    });
  };

  const handleAgreeDelete = (value) => {
    setDeleteDoc(value);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setOpenDelete(false);
    setIsLoading(true);
    const result = await deleteFormApi(deleteDoc.id);
    if (result.code === 200) {
      getConsultantListing();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage <= 0) {
      setPageCount(1);
    } else {
      setPageCount(newPage + 1);
    }
  };
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  //   console.log(newPage, "sdfsdkjlflkasd");
  // };
  const handleChangePages = (event, newPage) => {
    if (newPage <= 0) {
      setPage(0);
      setPageCount(1);
    } else {
      setPage(newPage - 1);
      setPageCount(newPage);
    }
    localStorage.setItem("member_page_number", newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDetail = (value) => {
    navigate(`/bookings/booking-detail/${value._id}`, {
      state: value,
    });
  };
  const handleNavigate = () => {
    navigate(`/form-templates/add-template`);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  const filteredUsers = applySortFilter(
    userList,
    getComparator(order, orderBy),
    filterName
  );

  // console.log(member, "membermembermember");
  useEffect(() => {
    getConsultantListing();
  }, []);

  const MENU_OPTIONS = [
    // {
    //   label: "Edit",
    //   icon: "akar-icons:edit",
    //   handleClick: handleEdit,
    // },
    {
      label: "View Detail",
      icon: "pajamas:eye",
      handleClick: handleDetail,
    },
    // {
    //   label: "Delete",
    //   icon: "ant-design:delete-twotone",
    //   handleClick: handleAgreeDelete,
    // },
  ];
  const isUserNotFound = filteredUsers.length === 0;
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDelete}
      />

      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-8 col-sm-12">
            <h2>Bookings</h2>
          </div>
          <div className="col-lg-4 col-sm-12 text-end">
            {/* <button onClick={handleNavigate} className="small-contained-button">
              Add Template
            </button> */}
          </div>
        </div>

        <Card style={{ overflowX: "auto" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex flex-column align-items-start">
              <TablePagination
                rowsPerPageOptions={[50, 100, 150]}
                component="div"
                count={userList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Pagination
                count={totalPages + 1}
                page={pageCount}
                defaultPage={0}
                onChange={handleChangePages}
                className="pagination-style"
              />
            </div>
            <div className="d-flex align-items-center">
              <UserListToolbar
                numSelected={selected.length}
                filterName={filterName}
                onFilterName={handleFilterByName}
              />
            </div>
          </div>

          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={userList.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    const {
                      id,
                      name,
                      status,
                      payment_method,
                      lastName,
                      email,
                      profile,
                      goal,
                      address,
                      type,
                      memberCount,
                      amount,
                      transaction_date,
                      currency,
                      transaction_type,
                      created_by,
                      transaction_mode,
                      transaction_request_type,
                      payment_request,
                      sale_page,
                      plan,
                    } = row;
                    const isItemSelected = selected.indexOf(name) !== -1;

                    return (
                      <TableRow
                        hover
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}>
                        <TableCell>
                          {
                            <CustomPopoverSection
                              menu={MENU_OPTIONS}
                              data={row}
                            />
                          }
                        </TableCell>
                        <TableCell align="left">
                          {page * rowsPerPage + (i + 1)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={0}>
                            <Typography variant="subtitle2" noWrap>
                              <Tooltip title={convertToTitleCase(status)}>
                                <FiberManualRecordIcon
                                  sx={{
                                    height: 20,
                                    width: 20,
                                    // color: "green",
                                    color:
                                      status == "succeeded"
                                        ? "#046307"
                                        : status == "canceled"
                                        ? "#f6bd4b"
                                        : status == "requires_action"
                                        ? "#163dbd"
                                        : status == "payment_failed"
                                        ? "#4b0505"
                                        : "",
                                  }}
                                />
                              </Tooltip>
                              {htmlDecode(name ? name : "N/A")} (
                              {htmlDecode(email ? email : "N/A")})
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {convertCurrencyToSign(currency)} {amount}
                        </TableCell>
                        <TableCell align="left">
                          <Typography className="text-nowrap">
                            {/* {transaction_date
                              ? moment(transaction_date).format(
                                  "DD-MM-YYYY hh:mm A"
                                )
                              : "N/A"} */}
                            {transaction_date}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {created_by ? convertToTitleCase(created_by) : "N/A"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2" noWrap>
                            {convertToTitleCase(
                              htmlDecode(
                                transaction_type == "payment_plan"
                                  ? "sale_page"
                                  : transaction_type
                              )
                            )}{" "}
                            {/* ( {sale_page && sale_page?.sale_page_title + ` | `}
                            {payment_request &&
                              payment_request?.request_title + ` | `}
                            {plan && plan?.plan_title + ` | `}
                            {transaction_request_type &&
                              convertToTitleCase(transaction_request_type)}{" "}
                            ) */}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {convertToTitleCase(payment_method)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {transaction_mode}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </div>
    </>
  );
}
