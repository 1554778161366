import { invokeApi } from "../../bl_libs/invokeApi";

export const formsListApi = async (data) => {
  const requestObj = {
    path: `/api/dynamic_form`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const formsListDetail = async (id) => {
  const requestObj = {
    path: `/api/dynamic_form/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const getFaqSalePageListApi = async (id) => {
  const requestObj = {
    path: `/api/faq/faq_list_by_sale_page/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addFormApi = async (data) => {
  const requestObj = {
    path: `/api/dynamic_form`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateFormApi = async (id, data) => {
  const requestObj = {
    path: `/api/dynamic_form/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const deleteFormApi = async (id) => {
  const requestObj = {
    path: `/api/dynamic_form/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
