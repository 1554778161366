import { CircularProgress, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { transactionDetailApi } from "src/DAL/Transaction/transactionApi";
import { useSnackbar } from "notistack";
import moment from "moment";
import DetailsFormInformation from "./DetailsFormInformation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { convertCurrencyToSign } from "src/utils/constant";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function TransactionDetail() {
  const params = useLocation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [detailData, setDetailData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log(params, "paramsparams");
  useEffect(() => {
    console.log(state, "===== state ========== state =====");
  }, []);
  if (isLoading == true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      <div className="row">
        <Typography variant="h5" component="h2">
          {params.pathname.includes("booking")
            ? "Booking Detail"
            : " Transaction Detail"}
        </Typography>
      </div>

      <div className="row mt-3 solid-border">
        <div className="col-md-6 col-lg-6 mt-3">
          <div className="row">
            <div className="col-6  mt-1  mb-3">Page Title:</div>
            <div className="col-6  mt-1 text-capital text-muted">
              {state?.sale_page?.sale_page_title}
            </div>
            <div className="col-6  mt-1  mb-3">Payment Method:</div>
            <div className="col-6  mt-1 text-capital text-muted">
              {state?.payment_method}
            </div>
            <div className="col-6  mt-1    mb-3">Transaction Request Type:</div>
            <div className="col-6  mt-1  text-capital  text-muted">
              {state?.transaction_request_type}
            </div>
            <div className="col-6  mt-1  mb-3">Transaction Mode:</div>
            <div className="col-6  mt-1 text-capital text-muted">
              {state?.transaction_mode}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 mt-3">
          <div className="row">
            {!params.pathname.includes("pages") && (
              <>
                <div className="col-6  mt-1   mb-3">Plan Title:</div>
                <div className="col-6  mt-1   text-muted">
                  {state?.plan?.plan_title}
                </div>
              </>
            )}
            <div className="col-6  mt-1   mb-3">Amount:</div>
            <div className="col-6  mt-1   text-muted">
              {convertCurrencyToSign(state?.currency)} {state?.amount}
            </div>
            <div className="col-6  mt-1  mb-3">Transaction Date:</div>
            <div className="col-6  mt-1  text-muted">
              {moment(state?.transaction_date).format("YYYY MM DD")}
            </div>
            <div className="col-6  mt-1  mb-3">Transaction Status:</div>
            <div className="col-6  mt-1 text-capital text-muted">
              {state?.transaction_status}
            </div>{" "}
          </div>
        </div>
      </div>
      {state?.transaction_category == "booking" ? (
        <>
          <div className="row mt-3">
            <Typography variant="h5" component="h2">
              Parent Info
            </Typography>
          </div>

          <div className="row mt-3 solid-border">
            <>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  <div className="col-6  mt-1  mb-3">Name:</div>
                  <div className="col-6  mt-1 text-capital text-muted">
                    {state?.parent_info.user_name}
                  </div>

                  <div className="col-6  mt-1    mb-3">Contact Number:</div>
                  <div className="col-6  mt-1     text-muted">
                    {state?.parent_info.contact_number}
                  </div>
                  <div className="col-6  mt-1   mb-3">Email:</div>
                  <div className="col-6  mt-1  capitalaize  text-muted">
                    {state?.parent_info.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row"></div>
              </div>
            </>
          </div>
          <div className="row mt-3">
            <Typography variant="h5" component="h2">
              Child Info
            </Typography>
          </div>

          <div className="row mt-3 solid-border">
            <>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  <div className="col-6  mt-1  mb-3">Name:</div>
                  <div className="col-6 text-capital mt-1 capitalaize text-muted">
                    {state?.child_info.user_name}
                  </div>
                  <div className="col-6  mt-1   mb-3">
                    Emergency Contact Name
                  </div>
                  <div className="col-6  mt-1  text-capital  text-muted">
                    {state?.child_info.emergency_contact_name}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  <div className="col-6  mt-1    mb-3">Age Group:</div>
                  <div className="col-6  mt-1     text-muted">
                    {state?.child_info.age_group} Years
                  </div>
                  <div className="col-6  mt-1   mb-3">
                    Emergency Contact Number
                  </div>
                  <div className="col-6  mt-1  capitalaize  text-muted">
                    {state?.child_info.emergency_contact_number}
                  </div>
                </div>
              </div>
            </>
          </div>

          <div className="row mt-3">
            <Typography variant="h5" component="h2">
              Form Detail
            </Typography>
          </div>
          <div className="row mt-3 solid-border">
            <>
              <p style={{ fontWeight: 600, fontSize: "18px" }}>Time Slot</p>
              <>
                <div className="col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-6  mb-3">Start Time:</div>
                    <div className="col-6  capitalaize text-muted">
                      {state?.selected_timeslot.start_time}
                    </div>
                    <div className="col-6  mt-1   mb-3">Reserved Seats</div>
                    <div className="col-6  mt-1  capitalaize  text-muted">
                      {state?.selected_timeslot.reserved_seats}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                  <div className="row">
                    <div className="col-6  mb-3">End Time:</div>
                    <div className="col-6   text-muted">
                      {state?.selected_timeslot.end_time}
                    </div>
                    <div className="col-6  mt-1   mb-3">Total Seats</div>
                    <div className="col-6  mt-1  capitalaize  text-muted">
                      {state?.selected_timeslot.total_seats}
                    </div>
                  </div>
                </div>
              </>
              <DetailsFormInformation form={state?.form_questions_answers} />
            </>
          </div>
        </>
      ) : (
        <>
          <div className="row mt-3">
            <Typography variant="h5" component="h2">
              Member Info
            </Typography>
          </div>

          <div className="row mt-3 solid-border">
            <>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row">
                  <div className="col-6  mt-1  mb-3">Name:</div>
                  <div className="col-6  mt-1 capitalaize text-muted">
                    {state?.user_name}
                  </div>

                  {/* <div className="col-6  mt-1    mb-3">Contact Number:</div>
                  <div className="col-6  mt-1     text-muted">
                    {state?.parent_info.contact_number}
                  </div> */}
                  <div className="col-6  mt-1   mb-3">Email:</div>
                  <div className="col-6  mt-1  capitalaize  text-muted">
                    {state?.email}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mt-3">
                <div className="row"></div>
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
}
